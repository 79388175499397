import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/app/receivedata/:hash/authorize/:authorizeId',
      name: 'appstore-receive-data',
      beforeEnter: (to, from, next) => {
        localStorage.setItem('appstore-hash', to.params.hash)
        localStorage.setItem('appstore-authorizeId', to.params.authorizeId)
        next({ name: 'appstore', params: { hash: to.params.hash } })
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../views/NotFoundView.vue')
    },
    {
      path: '/appstore/:hash/authorize',
      name: 'appstore',
      component: () => import('../views/AppStoreView.vue')
    },
    {
      path: '/appstore/install-error',
      name: 'install-error',
      component: () => import('../views/InstallErrorView.vue')
    }
  ]
})

export default router
