import { createI18n } from 'vue-i18n'

function loadLocaleMessages() {
  const locales = import.meta.glob('../locales/*.json', { eager: true })

  const messages = {}

  function processFiles(files) {
    Object.keys(files).forEach((key) => {
      const matched = RegExp(/\/locales\/(.*)\.json$/).exec(key)

      if (matched && matched.length > 1) {
        const locale = matched[1]
        const dirPath = key.split('/').slice(0, -1).join('/')
        const jsonFiles = Object.keys(files).filter(
          (path) => path.startsWith(`${dirPath}/`) && path.endsWith('.json')
        )

        if (jsonFiles.length > 0) {
          messages[locale] = {
            ...(messages[locale] || {}),
            ...files[key].default
          }
        }
      }
    })
  }

  processFiles(locales)

  return messages
}

const config = {
  defaultLocale: 'pt-BR',
  fallbackLocale: 'pt-BR'
}

const i18n = createI18n({
  locale: import.meta.env.VITE_APP_I18N_LOCALE || config.defaultLocale,
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || config.fallbackLocale,
  legacy: false,
  messages: loadLocaleMessages()
})

export default i18n
