import './assets/main.css'
import '@appmax_npm/ds-appmax-v3/dist/style.css'
import 'material-icons/iconfont/material-icons.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { configureAppmaxDS } from '@appmax_npm/ds-appmax-v3'
import { Vue3Mq } from 'vue3-mq'

import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'

const app = createApp(App)
const appmaxDS = configureAppmaxDS()

app.use(createPinia())
app.use(router)
app.use(appmaxDS)

app.use(Vue3Mq, {
  breakpoints: {
    mobile: 0,
    tablet: 767,
    desktop: 1200
  }
})

app.use(i18n)

app.mount('#app')
